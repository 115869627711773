<template>
  <div class="socialnetwork">

      <b-container>
          <b-row> 
            <b-col cols="12">
              <h3>Inspirálódj!</h3>

              <p>Még több izgalmas tartalomra vágysz? <br>
                Kövess minket Instagramon és Facebookon is!</p> 
            </b-col>
          </b-row>

          <b-row> 
            <b-col cols="12">
              <div class="socialnetwork-content">
				
              <a href="https://www.instagram.com/kunsagieden/" class="social-container" target="_blank" @click="setDataLayer('social_page_link', 'instagram', routeType)">
                <div class="instagram-img-holder">
                  <img src="@/assets/images/design/pie.jpg" alt="">
                </div>
                  <div class="social-title-link">
                  <img src="@/assets/images/icons/facebook-component.svg" alt="Kunsági Éden instagram">
                      <h2>Instagram</h2>  
                  </div>
              </a>
 
              <a href="https://www.facebook.com/kunsagieden" class="social-container" target="_blank" @click="setDataLayer('social_page_link', 'facebook', routeType)">
                <div class="fb-img-holder">
                  <img src="@/assets/images/design/cooking.jpg" alt="">
                </div>
                  <div class="social-title-link" >
                  <img src="@/assets/images/icons/instagram-component.svg" alt="Kunsági Éden facebook">
                      <h2>Facebook</h2>  
                  </div>
              </a>

              </div>

             
          
            </b-col>
       </b-row>
      </b-container>
    </div>
 
</template>

<script>
export default {
  computed:{
    routeType(){
      let routeName = this.$route.name
      let routeType = null

      console.log('route name', routeName)

      switch (routeName) {
        case 'Home':
          routeType = 'Fooldal'
          break;
        case 'About':
          routeType = 'Rolunk'
          break;
        case 'News':
          routeType = 'Hirek'
          break;
        case 'NewsDetails':
          routeType = 'hir reszletes'
          break;
        case 'PromoDetails':
          routeType = 'promocio reszletes'
          break;
        case 'Products':
          routeType = 'termekek'
          break;
        case 'Recipes':
          routeType = 'receptek'
          break;
        case 'RecipeDetails':
          routeType = 'recept reszletes'
          break;
        case 'Contact':
          routeType = 'kapcsolat'
          break;
        default:
          routeType = ''
      }

      return routeType
      
    }
  }
}
</script>