<template>
  <div class="about">
        <div class="about-hero"> 
          <div class="about-hero__text">
            <b-container fluid="xl">
              <b-row>
                <b-col cols="12">
                  <div class="about-hero-all">
                    
                    <div class="about-hero-block">
                      <h1 class="h1-dots">Rólunk</h1>
                      <div class="about-hero-paragraph">Büszkék vagyunk rá, hogy a Kunsági Éden étolaj minden cseppje magyar – ez az, ami igazán különlegessé teszi a hazai piacon.
                        <img src="@/assets/images/icons/magyar_elemiszer_bank_logo.svg" alt="Magyar Élelmiszerbank" />
                      </div>                    
                  </div>        
                    <div class="video-hero-block">
                      <iframe class="responsive-iframe" src="https://www.youtube.com/embed/Ey7XU85Asho" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>           
                  </div>
                </b-col>               
              </b-row>           
            </b-container>
          </div>
        </div>
    
        <div class="about-content space-left-right">
            <b-container fluid="xl" class="">
                 <div class="about-content-first">
                     <b-row class="space-left-right">
                        <b-col cols="12" md="6" lg="6">
                            <h2>A magyar étolaj</h2>
                            <p>A Kunsági Éden története egy egészen kis üzemben indult, évtizedekkel ezelőtt. A kezdetektől fogva arra törekedtünk, hogy egy <strong>100%-ban magyar</strong> terméket készítsünk, ami magába foglalja a hazai termőföld gazdagságát és sok éves szakértelmünket.</p>
                            <div class="video-hero-block-mobile">
                                <iframe width="635" height="357" src="https://www.youtube.com/embed/Ey7XU85Asho" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                           </div>  
                            <p class="about-light-brown">Amellett, hogy napjainkban már a legmodernebb technológiával, környezettudatosan dolgozunk, a célunk továbbra is ugyanaz: palackba zárni a kunsági napfényt és eljuttatni mindenki otthonába.</p>
                        </b-col>
                  
                        <b-col cols="12" md="6" lg="6" class="txt-gap">
                          <p class="about-light-brown">Ez a vidéki idill az, ami mosolyt csal az emberek arcára. És ezek azok az ízek, amik különlegessé teszik a közös pillanatokat. </p>
                          <p class="about-light-brown">Hiszünk benne, hogy a szívvel és Kunsági Édennel elkészített ételek segítenek megőrizni kultúránkat és boldogabbá tenni hétköznapjainkat.</p>
                        </b-col>
                      </b-row>    
                  </div>

              <b-row class="space-left-right">
                <div class="about-line-img">
                    <img src="@/assets/images/bgr/line-about.svg" alt="Kunsági Éden line">
                </div>

                <div class="about-line-img-mob">
                    <img src="@/assets/images/design/line-mobile.svg" alt="Kunsági Éden line">
                </div>
              </b-row> 

               <b-row>
                
                    
                      <b-col cols="12" md="6" lg="6" class="ordered">
                        <div class="about-imgkunsagi-img">
                          <img src="@/assets/images/design/img_packshot.jpg" alt="Kunsági Éden étolaj">
                        </div>
                      </b-col>
              
                   
                      <b-col cols="12" md="6" lg="6" class="ordered txt-gap-middle space-right">
                        <h2>Magyar termék</h2>
                        <p class="about-light-brown">Magyarország csodás adottságokkal rendelkezik a napraforgó-termesztéshez. </p>
                        <p class="about-light-brown">A Kunsági Éden étolajak kínálatában magyar alapanyagokból készült termékek is megtalálhatóak, melyek magyar munkaerő segítségével készülnek.</p>
                      </b-col> 
             

                </b-row> 

              <b-row class="space-left-right">
                <div class="about-line-img">
                    <img src="@/assets/images/bgr/line-about.svg" alt="Kunsági Éden line">
                </div>

                <div class="about-line-img-mob">
                    <img src="@/assets/images/design/line-mobile.svg" alt="Kunsági Éden line">
                </div>
              </b-row> 

              <div class="about-content-last">
                  <b-row class="space-left-right">
                    <b-col cols="12" md="6" lg="6">
                        <h2>Környezettudatosság</h2>
                        <p class="about-light-brown">A Kunsági Édennél kiemelten fontosnak tartjuk a természet védelmét és a fenntarthatóságot. Igyekszünk minél többet visszaadni a magyar földnek cserébe azért a csodálatos gazdagságért, amit tőle kapunk.</p>
                        <p class="about-light-brown">Ezért a palackjaink készítése során igyekszünk a lehető legkevesebb műanyagot felhasználni, termékeinket pedig FSC minősítéssel ellátott kartondobozokba csomagoljuk és FSC minősítéssel ellátott címkékkel látjuk el, ami azt jelenti, hogy az ezekhez felhasznált papír ellenőrzött és fenntartható erdőgazdálkodásból származik.</p>
                    </b-col> 

                    <b-col cols="12" md="6" lg="6" class="txt-gap">
                        <p class="about-light-brown">Emellett további intézkedésekkel csökkentjük az egyéb csomagolóanyagok, illetve a Kunsági Éden előállítása során keletkező hulladék mennyiségét.</p>
                        <p class="about-light-brown">Pontosan tudjuk, hogy mivel tartozunk szeretett bolygónknak. Minden erőnkkel azon vagyunk, hogy megőrizzük a magyar tájat gyermekeinknek és unokáinknak, hogy ők is megélhessék a csodáit.</p>
                    </b-col>

                  </b-row> 
              </div>

              <b-row >
                <div class="about-bg-middle">
                  <img src="@/assets/images/design/half-design-circle.svg" alt="bg">
                </div>
                <div class="about-line-img-mob space-left-right">
                    <img src="@/assets/images/design/line-mobile.svg" alt="Kunsági Éden line">
                </div>
              </b-row> 

            </b-container>
             
        </div>

<Socialnetwork  />  
         


  </div>
</template>

<script>
import Socialnetwork from '@/components/layout/Socialnetwork'

export default {

  components: {
    Socialnetwork,
  },
  mounted(){
            this.setMetaTags({
                title: 'Kunsági Éden - Rólunk', 
                desc: '', 
                keywords: '', 
                ogType: 'website', 
                ogTitle: 'Kunsági Éden - Rólunk',
                ogImage: null, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc:''
            })
  }
}
</script>